<template>
  <div class="chat">
    <div class="chat-wrapper">
      <div
        v-for="chat in chats"
        :key="chat.key"
        :class="['chat-message-wrapper', chat.main ? 'right' : 'left']"
      >
        <div class="chat-icon">
          <div
            class="icon"
            :style="{
              backgroundImage:
                'url(' + (chat.main ? chat.main.image : chat.sub.image) + ')'
            }"
          ></div>
        </div>
        <div
          class="chat-message"
          @click="onClickMessage"
          v-html="chat.message"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chat',
  props: {
    bg: {
      type: String,
      default: null
    },
    history: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    stories: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    chats() {
      const chats = [];
      for (let h = 0; h < this.history.length; h++) {
        const chat = this.stories[this.history[h].key];
        if (chat.type === 'chat') {
          chats.push(chat);
        }
      }
      return chats;
    }
  },
  methods: {
    onClickMessage(evt) {
      if (evt.srcElement.classList.contains('annotation')) {
        // annotation
        evt.srcElement.classList.toggle('active');
      } else if (this.chats[this.chats.length - 1].next) {
        // next
        console.log('next event is fired');
        this.$emit('next');
      }
    },
    onOptionSelected(option) {
      console.log('next event is fired', option);
      this.$emit('next', option);
    }
  }
};
</script>

<style scoped>
/*
.chat {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.chat-wrapper {
  max-width: 1000px;
  width: 90%;
}

.chat-message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.chat-icon-right,
.chat-icon-left {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 5em;
  height: 5em;
  border-radius: 5em;
  background-color: #fff;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.chat-icon-right {
  margin-left: 0.5em;
}

.chat-icon-left {
  margin-right: 0.5em;
}

.chat-message-right,
.chat-message-left {
  border-radius: 1em;
  background-color: #fff;
  min-height: 5em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.chat-message-right {
  padding-left: 1em;
}

.chat-message-left {
  padding-right: 1em;
}
*/
</style>
